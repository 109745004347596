import React from 'react'
import { Link } from 'gatsby'
import { sendClickedEvent } from 'helpers/analytics/event-tracking'
import Image from 'components/image/image'
import './article-first.sass'

const getImage = (image, title) => {
  if (image) {
    return (
      <div className="ArticleListItem__thumb--container">
        <Image image_url={image} alt={`Background image for ${title} article`} className="ArticleListItem__thumb"/>
      </div>
    )
  }
}

const getNotebookImage = (image, title) => {
   // this does not use the Image component for ease of use of other team members
  if (image) {
    return (
      <div className="ArticleListItem__thumb--container">
        <img src={`/notebooks/${image}`} alt={`Background image for ${title} article`} className="ArticleListItem__thumb"/>
      </div>
    )
  }
}

const Article = ({ data, ...props }) => {
  const image = data.tileImage ? data.tileImage : data.image;

  return (
    <article
      className="ArticleListItem"
      onClick={() => sendClickedEvent(`ArticleListItem__${data.title}`)}>
      <Link className="ArticleListItem__link" to={data.path}>
        {data.path.includes('/notebooks/')
          ? getNotebookImage(data.image, data.title)
          : getImage(image, data.title)
        }
        <div className="ArticleListItem__content px-4 pt-4">
          <h2 className="ArticleListItem__title h4">
            {data.title}
          </h2>
          <p className="ArticleListItem__text">
            {data.card.snippet}
          </p>
        </div>
        <div className="ArticleListItem__footer px-4 pb-4">
          <hr className="mt-0"/>
          <div className="d-flex align-items-center  ArticleListItem__link-text">
            <span className="flex-grow-1 k-text--mono small">
              {data.card.linktext
                ? data.card.linktext
                : 'Read more'
              }
            </span>
            <span>&rarr;</span>
          </div>
        </div>
      </Link>
    </article>
  )
}


export default Article
