import React, { Component } from 'react'
import { graphql } from 'gatsby'
import moment from 'moment'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import ArticleListItem from 'components/articles/article-list-item'
import StickySidebar from 'components/sidebars/sticky-sidebar'
import NewsList from 'components/news/news-list'
import GetInTouch from 'components/get-in-touch/get-in-touch'


class ArticlesPage extends Component {

  constructor(props) {
    super(props)
    this.articlesPerPage = 6
    this.articlesTotal = this.props.data.allArticlesYaml.edges.length
    this.lastScroll = typeof window !== 'undefined' ? window.pageYOffset : 0

    this.state = {
      showingMore: this.articlesTotal > this.articlesPerPage,
      articlesToShow: typeof window !== 'undefined' && window.articlesToShow
        ? window.articlesToShow
        : this.articlesPerPage,
    }
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll)
    window.articlesToShow = this.state.articlesToShow
  }

  getMore() {
    const articlesToShow = this.state.articlesToShow + this.articlesPerPage
    this.setState({
      showingMore: articlesToShow <= this.articlesTotal,
      articlesToShow
    })
    this.updating = false
  }

  handleScroll = (e) => {
    if (this.state.showingMore && !this.updating && window.pageYOffset > this.lastScroll) {
      let gridBottom = document.getElementsByClassName('k-article-grid')[0].getBoundingClientRect().bottom
      if (window.pageYOffset + window.innerHeight > gridBottom - 100) {
        this.updating = true
        this.getMore()
      }
    }
    this.lastScroll = window.pageYOffset
  }

  concatArticlesAndNotebooks(){
    let articles = [...this.props.data.allArticlesYaml.edges]; //copy to not mutate orginal
    const notebooks =  [...this.props.data.allJupyterNotebook.edges]; //copy to not mutate orginal

    notebooks.forEach(note => articles.push({ node: note.node.metadata}))


    articles = articles.sort((a, b) => {
      a = a.node.date.replace('T00:00:00.000Z', '');
      b = b.node.date.replace('T00:00:00.000Z', '');
      const a_date = moment(a)
      const b_date = moment(b)
      return b_date.diff(a_date)
    });

    return articles;
  }

  getArticles() {
    return this.concatArticlesAndNotebooks()
      .slice(0, this.state.articlesToShow)
      .map((article, i) => {
        return (
          <ArticleListItem
            key={i}
            data={article.node}>
            {article.node.title}
          </ArticleListItem>
        )
      })
  }

  render() {
    return (
      <Layout
        bodyClass="k-reverse-header">
        <SEO
          title="Articles | Read our take on the latest in AI/ML"
          keywords={['Kortical articles', 'AI articles', 'AI resources', 'ML articles', 'Artificial Intelligence']}
          description="Read the latest AI news and access our free resources and whitepapers." />
        <div className="k-bg--sidebar">
          <div className="container-fluid">
            <div className="row">
              <div className="col k-bg--white k-section-pt--lg">
                <h1 className="k-text--heading-mono">Articles</h1>
                <p className="h2">
                  Learn about Kortical’s approach to artificial intelligence and machine learning with a focus on accelerating value from AI.
                </p>
                <div className="k-article-grid my-5">
                  {this.getArticles()}
                </div>
                {!this.state.showingMore &&
                  <div className="k-border--top p-3 mb-3 text-center small"
                    >No further articles.
                  </div>
                }
              </div>
              <aside className="col-lg-auto k-section-pt--lg-sidebar k-bg--grey">
                <StickySidebar>
                  <h2 className="k-text--heading-mono">News</h2>
                  <NewsList />
                </StickySidebar>
              </aside>
            </div>
          </div>
        </div>
        <GetInTouch />
      </Layout>
    )
  }
}

export default ArticlesPage

export const pageQuery = graphql`
  query {
    allArticlesYaml {
      edges {
        node {
          path
          date
          title
          image
          tileImage
          card {
            snippet
            linktext
          }
        }
      }
    }
    
    allJupyterNotebook {
      edges {
        node {
          id
          html
          metadata {
            path
            title
            date
            image
            card {
              linktext
              snippet
            }
          }
        }
      }
    }
  }
`
